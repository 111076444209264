import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import axios from "axios";
import omniaService from '../services/omnia';
import "../assets/css/solif.css";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import wlogo from "../assets/img/apex-logotipo.png";
import ultimate from "../assets/img/apex-ultimate-xt-series.jpg";
import globestar from "../assets/img/apex-globestar-series.jpg";
import green from "../assets/img/apex-ultimate-green-xt.jpg";
import izq from "../assets/img/icono-flecha-izq.png";
import der from "../assets/img/icono-flecha-der.png";
import estrellas from "../assets/img/icono-estrellas.png";
import local from "../assets/img/apex-local-professional-installation.jpg";
import inmediate from "../assets/img/apex-inmediate-delivery.jpg";
import quality from "../assets/img/apex-quality-assurance.jpg";
import tel from "../assets/img/icono-phone.png";
import ilogo from "../assets/img/apex-isotipo.png";

const Solif = () => {
    const currentUrl = window.location.href;
    const [lead, setLead] = useState({});

    useEffect(() => {
        new Glide('.glide', {
            type: 'carousel',
            perView: 3,
            startAt: 0,
            focusAt: 0,
            gap: 40,
            breakpoints: {
                500: {
                    perView: 1,
                    gap: 0
                }
            }
        }).mount();

        new Glide('.glide2', {
            type: 'carousel',
            perView: 1,
            startAt: 0,
            focusAt: 0,
            breakpoints: {
                500: {
                    perView: 1,
                    gap: 0
                }
            }
        }).mount();

        new Glide('.glide3', {
            type: 'carousel',
            perView: 3,
            startAt: 0,
            focusAt: 0,
            gap: 40,
            breakpoints: {
                500: {
                    perView: 1,
                    gap: 0
                }
            }
        }).mount();
    }, []);

    const obtenerMensajeDeFormulario = (lead) => {
        let mensaje = `<br><br>Name: ${lead.name}<br>`
            + `Phone: ${lead.phone}<br>`
            + `Email: ${lead.email}<br>`
            + `Message: ${lead.message}`;

        if (lead.utm_id) mensaje += `<br>UTM ID: ${lead.utm_id}`;
        if (lead.utm_campaign) mensaje += `<br>UTM Campaña: ${lead.utm_campaign}`;
        if (lead.utm_source) mensaje += `<br>UTM Fuente: ${lead.utm_source}`;
        if (lead.utm_medium) mensaje += `<br>UTM Medio: ${lead.utm_medium}`;
        if (lead.utm_content) mensaje += `<br>UTM Contenido: ${lead.utm_content}`;
        if (lead.utm_term) mensaje += `<br>UTM Término: ${lead.utm_term}`;

        return mensaje;
    };

    const onSubmitHandler = (event) => {
        event.preventDefault();
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
    
        let error = false;
        let msj = "";
        if (!error && lead.name.length <= 2) {
            error = true;
            msj = "The name field is required";
        }
        if (!error && lead.phone.length <= 7) {
            error = true;
            msj = "The phone field is required";
        }
        if (!error && lead.email.length <= 5) {
            error = true;
            msj = "The email field is required";
        }
        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";
    
            data.token = "RnV0dXJpdGUyMDIw";
            data.client = 191;
    
            axios.post('https://omnia.futurite.com/api/save-lead-form', data)
                .then((response) => {
                    const mensajeFormulario = obtenerMensajeDeFormulario(lead);
                    const emailData = {
                        key: 98030967,
                        to: ["herbert@apexindustrialtires.com"],
                        message: "Apex form has been filled out!\n\n" + mensajeFormulario
                    };
    
                    axios.post('https://omnia.futurite.com/api/mailing', emailData)
                        .then((response) => {
                            // Alerta y limpieza de campos
                            alert("Form submitted successfully!");
                            event.target.reset();
                            setLead({});
                        })
                        .catch((error) => {
                            console.log("Error al enviar el correo:", error);
                        });
                })
                .catch((error) => {
                    console.log("Error al guardar el lead:", error);
                });
        } else {
            alert(msj);
        }
    };    

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }

    return (
        <div className="solif">
            <Helmet>
                <title>Apex</title>
                <meta name="description" content="" />
                <link rel="canonical" href="" />
                <script type="text/javascript">
                    {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NXPWHN63');
                    `}
                </script>
            </Helmet>
            <div className="container-fluid header">
                <nav className="navbar navbar-expand-lg mobile-item">
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <a href="#" className="link">Home</a>
                            <a href="#products" className="link">Products</a>
                            <a href="#testimonials" className="link">Testimonials</a>
                            <a href="#differentiators" className="link">Differentiators</a>
                            <a href="#location" className="link">Location</a>
                            <a href="#contact" className="link">Contact</a>
                        </ul>
                    </div>
                    <div className="box mobile-item">
                        <img src={wlogo} alt="Apex" />
                    </div>
                </nav>
                <div className="row text-center text-white m-0">
                    <div className="col-md-3 p-0">
                        <div className="box desktop-item">
                            <img src={wlogo} alt="Apex" />
                        </div>
                    </div>
                    <div className="col-md-9 desktop-item test d-flex align-items-center justify-content-center lbox">
                        <div className="">
                            <a href="#" className="link">Home</a>
                            <a href="#products" className="link">Products</a>
                            <a href="#testimonials" className="link">Testimonials</a>
                            <a href="#differentiators" className="link">Differentiators</a>
                            <a href="#contact" className="link">Contact</a>
                            <a href="#location" className="link">Location</a>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <h1 className="fw-bold">
                            GRI PREMIUM SOLID Forklift TIRES
                        </h1>
                        <h2 className="fw-bold">
                            Revolutionize Your Fleet with Apex's GRI Forklift Tires in Texas!
                        </h2>
                        <p className="fw-l mb-4">
                            At Apex, we're proud to offer the top-of-the-line GRI premium solid forklift tires, specifically designed to meet the toughest demands of material handling. Our GRI tires are known for their unmatched durability, reliability, and efficiency, making them the perfect choice for any business looking to optimize their operations.
                        </p>
                        <h2 className="fw-l">
                            <a href="tel:+17134850299" className="btn-cta">Call Now</a>
                        </h2>
                    </div>
                </div>
            </div>
            {/* SEC 1 */}
            <div className="container-fluid sec1 d-pad" id="products">
                <div className="row">
                    <h2 className="fw-l text-blue text-center mb-5 fw-bold">
                        Why Choose GRI Forklift Tires from Apex?
                    </h2>
                    <div className="glide">
                        <div className="glide__track" data-glide-el="track">
                            <div className="glide__slides">
                                <div className="glide__slide text-center">
                                    <img src={ultimate} alt="Ultimate XT Series" />
                                    <h3 className="fw-l text-blue mt-4 mb-2">ULTIMATE <br /><span className="fw-bold">XT SERIES</span></h3>
                                    <p>
                                        &bull; Exceptional Performance: Engineered to excel in extreme conditions, ensuring your operations run smoothly no matter what. <br />
                                        &bull; Maximum Durability: Built to last, providing reliability you can count on.
                                    </p>
                                </div>
                                <div className="glide__slide text-center">
                                    <img src={globestar} alt="Globestar Series" />
                                    <h3 className="fw-l text-blue mt-4 mb-2">
                                        <span className="fw-bold">GLOBESTAR</span> <br /> SERIES
                                    </h3>
                                    <p>
                                        &bull; Unparalleled Reliability: Perfect for versatile applications across various industries, these tires won't let you down. <br />
                                        &bull; Top-Tier Durability: Designed to handle heavy loads and rigorous use with ease.
                                    </p>
                                </div>
                                <div className="glide__slide text-center">
                                    <img src={green} alt="Ultimate Green XT" />
                                    <h3 className="fw-l text-blue mt-4 mb-2">
                                        ULTIMATE <br /> <span className="fw-bold">GREEN XT</span>
                                    </h3>
                                    <p>
                                        &bull; Eco-Friendly Efficiency: The green solution that doesn't compromise on performance. These tires align with modern sustainability goals while delivering top-notch efficiency. <br />
                                        &bull; Innovation & Stewardship: Reflecting our commitment to environmental responsibility and cutting-edge technology.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                                <img src={izq} alt="Left arrow" />
                            </button>
                            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                                <img src={der} alt="Right arrow" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC 2 */}
            <div className="container-fluid sec2 d-pad" id="testimonials">
                <div className="row">
                    <h2 className="fw-l text-center fw-bold">Hear from Our Satisfied Customers</h2>
                    <div className="glide2 position-relative">
                        <div className="glide__track" data-glide-el="track">
                            <div className="glide__slides">
                                <div className="glide__slide text-center text-white box bg-blue">
                                    <h3 className="fw-l m-0">JOHN H., <span className="fw-bold">LOGISTICS MANAGER</span></h3>
                                    <p>
                                        "Apex truly transformed our forklift fleet with their expert tire installation. The immediate delivery and local expertise made all the difference. Our operations have never been smoother!"
                                    </p>
                                    <img src={estrellas} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img src={izq} alt="Left arrow" /></button>
                            <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img src={der} alt="Left arrow" /></button>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <a href="#contact" className="btn-cta">Explore Our Tire Fitting Services Today!</a>
                    </div>
                </div>
            </div>
            {/* SEC3 */}
            <div className="container-fluid sec3 d-pad" id="differentiators">
                <div className="row">
                    <h2 className="fw-l text-center fw-bold mb-5">Apex's Commitment to Excellence</h2>
                    <div className="glide3 position-relative">
                        <div className="glide__track" data-glide-el="track">
                            <div className="glide__slides">
                                <div className="glide__slide text-center">
                                    <img src={local} alt="" />
                                    <h2 className="fw-l text-center mt-4 text-blue">PROFESSIONAL <br /> <span className="fw-bold">INSTALLATION</span></h2>
                                    <p className="text-blue">Our team of experts ensures your tires are installed perfectly for optimal performance.</p>
                                </div>
                                <div className="glide__slide text-center">
                                    <img src={inmediate} alt="" />
                                    <h2 className="fw-l text-center mt-4 text-blue"><span className="fw-bold">INMEDIATE</span><br /> DELIVERY</h2>
                                    <p className="text-blue">We understand that time is money. That's why we offer quick delivery to keep your operations running without a hitch.</p>
                                </div>
                                <div className="glide__slide text-center">
                                    <img src={quality} alt="" />
                                    <h2 className="fw-l text-center mt-4 text-blue">QUALITY <br /> <span className="fw-bold">ASSURANCE</span></h2>
                                    <p className="text-blue">With Apex, you get the peace of mind that comes from knowing you're getting the best products and services available.</p>
                                </div>
                            </div>
                        </div>
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="<"><img src={izq} alt="Left arrow" /></button>
                            <button className="glide__arrow glide__arrow--right" data-glide-dir=">"><img src={der} alt="Right arrow" /></button>
                        </div>
                    </div>
                    <div className="fondo"></div>
                </div>
            </div>
            {/* SEC 4 */}
            <div className="container-fluid sec4 d-pad" id="location">
                <div className="row text-center">
                    <h2 className="fw-l">MAP AND <span className="fw-bold">LOCATION</span></h2>
                    <p className="">Visit Our Houston Location: Our interactive map guides you to our local office, where our team is ready to assist you in finding the perfect tire solution for your fleet. Experience our exceptional customer service firsthand.</p>
                </div>
            </div>
            <div className="fondo">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3466.425178212865!2d-95.3184442!3d29.6784504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864095d894d47aaf%3A0x8858ce6fae2a822e!2sApex%20Industrial%20Tires!5e0!3m2!1ses!2smx!4v1710452384746!5m2!1ses!2smx" className="w-100 mapa" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>

            </div>
            {/* SEC 5 */}
            <div className="container-fluid sec5 d-pad">
                <div className="row text-center">
                    <h3 className="fw-l text-blue">TAILORED SOLUTIONS FOR <span className="fw-bold">TEXAS DISTRIBUITORS:</span><br /> ELEVATE YOUR BUSINESS WITH APEX'S SPECIALIZED ATTENTION!</h3>
                </div>
            </div>

            <div className="container-fluid footer" id="contact">
                <div className="row">
                    <div className="banner-form">
                        <div className="row">
                            <div className="col-md-6 text-white">
                                <h3 className="fw-l">Get in Touch with Apex Today!</h3>
                                <p className="mb-4">Discover how Apex can elevate your business with specialized attention and top-quality GRI forklift tires. Contact us now to schedule a consultation or to request a quote.</p>
                                <div className="">
                                    <a href="tel:+17134850299" className="fw-bold fs-16 text-white link"><span><img src={tel} alt="" className="icono" /></span> Call Us: 713 485 0299</a> <br /> <br /> 
                                    <p className="fw-bold fs-16 text-white link">Visit Us: Stop by our Houston location to experience our exceptional customer service firsthand.</p>
                                </div>
                                <div className="row m-0">
                                    <div className="col-md-7 desktop-item">
                                        <div className="box text-center">
                                            <img src={ilogo} alt="" className="logo" />
                                        </div>
                                    </div>
                                    <div className="col-md-5"></div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <form onSubmit={onSubmitHandler}>
                                    <p className="fw-bold mb-4 text-white">Request Your Quote Now</p>
                                    <input type="text" name="name" placeholder="Name:" className="form-control mb-3" onChange={handleChange} value={lead.name || ""} required />
                                    <input type="text" name="email" placeholder="Email:" className="form-control mb-3" onChange={handleChange} value={lead.email || ""} required />
                                    <input type="text" name="phone" placeholder="Phone:" className="form-control mb-3" onChange={handleChange} value={lead.phone || ""} required />
                                    <textarea name="message" placeholder="Message:" className="form-control mb-3" onChange={handleChange} value={lead.message || ""} required />
                                    <button type="submit" className="btn-cta">Send</button>
                                </form>
                                <p className="fs-16 text-white text-center mt-5 copy">
                                    2024&copy; Apex. All rights reserved. <br />
                                    Design and development by digital marketing agency <a href="https://futurite.com/">Futurité</a>
                                </p>
                                <div className="box text-center mobile-item">
                                    <img src={ilogo} alt="" className="logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Solif;